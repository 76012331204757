import { Page, PageSwitcher } from '@bpm-web-app/components';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Joi from 'joi';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { FileUploader } from '@bpm-web-app/components/src/lib/shared/file-upload/file-uploader';
import DatePicker from 'react-datepicker';
import { useGenres } from '@bpm-web-app/swr-hooks';
import { Library } from '@bpm-web-app/download-api-sdk';
import { toast } from 'react-toastify';
import { showToast, isValidISRC } from '@bpm-web-app/utils';
import { ActionModal } from '@bpm-web-app/components/src/lib/shared/action-modal/action-modal';
import { CropImage } from '@bpm-web-app/components/src/lib/shared/crop-image/crop-image';
import escapeRegExp from 'lodash/escapeRegExp';
import { CustomIcon } from '@bpm-web-app/components/src/lib/shared/custom-icon/custom-icon';
import styles from './submit-music.module.css';
import Checkmark from '../../../../../assets/icons/artist-platform/checkmark.svg';
import { ArtistApplicationBooleanButton, ArtistApplicationButton } from '../artist-application-button/artist-application-button';
import { ApplicationSectionTitle, ApplicationSectionHeader, ApplicationSectionSmall, ApplicationErrorText } from '../artist-application-text/artist-application-text';
import { ArtistApplicationInputField } from '../artist-application-inputfield/artist-application-inputfield';
import { ArtistApplicationDropdown } from '../artist-application-dropdown/artist-application-dropdown';

interface SubmitMusicProps {
    handleBack: () => void;
    trackInfoData?: TrackInfoInputs;
    setTrackInfoData?: (data: TrackInfoInputs) => void;
    uploadFilesData?: UploadFilesInputs;
    setUploadFilesData?: (data: UploadFilesInputs) => void;
    uploadFilesFormRef?: MutableRefObject<HTMLFormElement>;
    trackInfoFormRef?: MutableRefObject<HTMLFormElement>;
    hasCompletedTrackInfo?: boolean;
    hasCompletedUploadFiles?: boolean;
    setHasCompletedTrackInfo?: (value: boolean) => void;
    setHasCompletedUploadFiles?: (value: boolean) => void;
    isLoading?: boolean;
    formState?: 'full' | 'track-info' | 'upload-files';
    artistName?: string;
}

export interface TrackInfoInputs {
    title: string;
    artist_name: string;
    genre: string;
    explicit?: boolean;
    release_date: Date;
    isrc: string;
    label: string;
    produced_by: string;
}

export interface UploadFilesInputs {
    artwork?: FileList;
    trackArtwork: FileList;
    originalVersion: FileList;
    cleanVersion?: FileList;
    instrumentalVersion?: FileList;
    acapellaVersion?: FileList;
    bassVersion?: FileList;
    melodyVersion?: FileList;
    drumsVersion?: FileList;
}

export function fileInputsToDictionary(trackInfoData: TrackInfoInputs, fileInputs: UploadFilesInputs) {
    const filesToUpload: { [key: string]: File } = {};
    const { cleanVersion, bassVersion, drumsVersion, melodyVersion, originalVersion, acapellaVersion, instrumentalVersion } = fileInputs;
    if (cleanVersion && cleanVersion.length) {
        // eslint-disable-next-line @typescript-eslint/dot-notation, prefer-destructuring
        filesToUpload['Clean'] = cleanVersion[0];
    }
    if (originalVersion && originalVersion.length) {
        const key = trackInfoData.explicit ? 'Dirty' : 'Clean';
        // eslint-disable-next-line @typescript-eslint/dot-notation, prefer-destructuring
        filesToUpload[key] = originalVersion[0];
    }
    if (bassVersion && bassVersion.length) {
        // eslint-disable-next-line @typescript-eslint/dot-notation, prefer-destructuring
        filesToUpload['Bass'] = bassVersion[0];
    }
    if (drumsVersion && drumsVersion.length) {
        // eslint-disable-next-line @typescript-eslint/dot-notation, prefer-destructuring
        filesToUpload['Drums'] = drumsVersion[0];
    }
    if (melodyVersion && melodyVersion.length) {
        // eslint-disable-next-line @typescript-eslint/dot-notation, prefer-destructuring
        filesToUpload['Melody'] = melodyVersion[0];
    }
    if (instrumentalVersion && instrumentalVersion.length) {
        // eslint-disable-next-line @typescript-eslint/dot-notation, prefer-destructuring
        filesToUpload['Instrumental'] = instrumentalVersion[0];
    }
    if (acapellaVersion && acapellaVersion.length) {
        // eslint-disable-next-line @typescript-eslint/dot-notation, prefer-destructuring
        filesToUpload['Acapella'] = acapellaVersion[0];
    }
    return filesToUpload;
}

export function SubmitMusic({
    trackInfoData,
    setTrackInfoData,
    uploadFilesData,
    setUploadFilesData,
    handleBack,
    uploadFilesFormRef = useRef(),
    trackInfoFormRef = useRef(),
    isLoading,
    hasCompletedUploadFiles = false,
    hasCompletedTrackInfo = false,
    setHasCompletedTrackInfo,
    setHasCompletedUploadFiles,
    formState = 'full',
    artistName,
}: SubmitMusicProps) {
    const [selectedPage, setSelectedPage] = useState<number>(0);
    const [isExplicit, setIsExplicit] = useState<boolean | null>(trackInfoData?.explicit ?? null);
    const today = useMemo(() => new Date(), []);
    const { genres } = useGenres(Library.Artist.toString());
    const [showUploadProfilePhoto, setShowProfilePhoto] = useState(false);
    const [showUploadTrackArtwork, setShowTrackArtwork] = useState(false);
    const [uploadedImage, setUploadedImage] = useState<string>('');
    const [uploadedTrackArtwork, setUploadedTrackArtwork] = useState<string>('');

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [selectedPage]);

    // TRACK INFO FORM
    const schemaTrackInfo = Joi.object({
        title: Joi.string().required().messages({ 'string.empty': 'Track title is required.' }),
        artist_name: artistName ?
            (
                Joi.custom((obj, helper) => {
                    const artistsNamesText = obj as string | undefined;

                    if (artistsNamesText === undefined || artistsNamesText === '') {
                        return helper.error('string.empty');
                    }

                    try {
                        if (artistsNamesText !== undefined && new RegExp(`(?<=\\s|^)${escapeRegExp(artistName)}(?=\\s|$)`).test(artistsNamesText)) {
                            return obj;
                        }
                    } catch {
                        return obj;
                    }
                    return helper.error('required.message');
                }).required().messages({ 'string.empty': 'Artist(s) name is required.', 'required.message': `Artists names should include your name "${artistName}"` })
            ) : Joi.string().required().messages({ 'string.empty': 'Artist name is required.' }),
        genre: Joi.string().required().messages({ 'string.empty': 'Genre is required.' }),
        explicit: Joi.custom((obj, helper) => {
            const explicit = obj as boolean | undefined;

            if (isExplicit !== null) {
                return obj;
            }
            if (explicit === undefined) {
                return helper.error('required.message');
            }

            if (explicit !== true && explicit !== false) {
                return helper.error('required.message');
            }
            return obj;
        }).required().messages({ 'required.message': 'Explicit selection is required.' }),
        release_date: Joi.date().optional().allow(null, ''),
        isrc: Joi.string().custom((obj, helper) => {
            const isrcText = obj as string | undefined;
            if (isrcText === undefined || isrcText === '') {
                return helper.error('required.message');
            }

            const cleanedISRC = isrcText.replace(/-/g, '').toUpperCase();

            if (cleanedISRC.trim().length !== 12) {
                return helper.error('required.length');
            }

            if (isValidISRC(cleanedISRC)) {
                return obj;
            }
            return helper.error('required.message');
        }).messages({ 'required.message': 'ISRC is invalid, please try again.', 'required.length': 'ISRC must be 12 digits long. Please try again.' }).required(),
        label: Joi.string().optional().allow(null, ''),
        produced_by: Joi.string().optional().allow(null, '')
    });

    const {
        register: registerTrackInfo,
        handleSubmit: handleSubmitTrackInfo,
        setValue: setValueTrackInfo,
        formState: { errors: errorsTrackInfo },
        getValues: getValuesTrackInfo,
        control: controlTrackInfo,
    } = useForm<TrackInfoInputs>({
        defaultValues: useMemo(
            () => ({
                title: trackInfoData && trackInfoData.title ? trackInfoData.title : '',
                artist_name: artistName || (trackInfoData && trackInfoData.artist_name ? trackInfoData.artist_name : ''),
                genre: trackInfoData && trackInfoData.genre ? trackInfoData.genre : '',
                explicit: trackInfoData && trackInfoData.explicit ? trackInfoData.explicit : null,
                release_date: trackInfoData && trackInfoData.release_date ? trackInfoData.release_date : null,
                isrc: trackInfoData && trackInfoData.isrc ? trackInfoData.isrc : '',
                label: trackInfoData && trackInfoData.label ? trackInfoData.label : '',
                produced_by: trackInfoData && trackInfoData.produced_by ? trackInfoData.produced_by : '',
            }),
            [trackInfoData, artistName]
        ),
        resolver: joiResolver(schemaTrackInfo),
    });

    useEffect(() => {
        Object.keys(errorsTrackInfo).forEach((err) => {
            showToast({ type: 'error', message: (errorsTrackInfo as any)[err].message || 'An error has occurred. Please try again.' });
        });
        if (Object.keys(errorsTrackInfo).length === 0) {
            toast.dismiss();
        }
    }, [errorsTrackInfo]);

    const saveTrackInfoDraftValues = useCallback(() => {
        if (formState !== 'upload-files' && setTrackInfoData) setTrackInfoData(getValuesTrackInfo());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValuesTrackInfo]);

    const handleBackHandler = useCallback(() => {
        handleBack();
        saveTrackInfoDraftValues();
    }, [handleBack, saveTrackInfoDraftValues]);

    const handlerTrackInfo: SubmitHandler<TrackInfoInputs> = useCallback((data) => {
        if (setTrackInfoData) {
            setTrackInfoData({
                title: data.title,
                artist_name: data.artist_name,
                genre: data.genre,
                explicit: isExplicit || undefined,
                release_date: data.release_date ?? today,
                isrc: data.isrc,
                label: data.label,
                produced_by: data.produced_by,
            });
        }
        setSelectedPage(1);
        if (setHasCompletedTrackInfo) setHasCompletedTrackInfo(true);
    }, [isExplicit, setHasCompletedTrackInfo, setTrackInfoData, today]);

    // UPLOAD FILES FORM
    const schemaUploadFiles = Joi.object({
        artwork: formState === 'upload-files' ? (
            Joi.custom((obj, helper) => {
                const artwork = obj as FileList | undefined;
                if (!artwork) {
                    return helper.error('required');
                }
                if (artwork.length === 0) {
                    return helper.error('required');
                }
                return obj;
            }).required().messages({ required: 'Photo upload is required.' })
        ) : (Joi.any().optional().allow(null, '')),
        trackArtwork: Joi.custom((obj, helper) => {
            const trackArtwork = obj as FileList | undefined;
            if (!trackArtwork) {
                return helper.error('required');
            }
            if (trackArtwork.length === 0) {
                return helper.error('required');
            }
            return obj;
        }).required().messages({ required: 'Track artwork is required.' }),
        originalVersion: Joi.custom((obj, helper) => {
            const trackArtwork = obj as FileList | undefined;
            if (!trackArtwork) {
                return helper.error('required');
            }
            if (trackArtwork.length === 0) {
                return helper.error('required');
            }
            return obj;
        }).required().messages({ required: 'Original version is required.' }),
        cleanVersion: Joi.any().optional().allow(null, ''),
        instrumentalVersion: Joi.any().optional().allow(null, ''),
        acapellaVersion: Joi.any().optional().allow(null, ''),
        bassVersion: Joi.any().optional().allow(null, ''),
        melodyVersion: Joi.any().optional().allow(null, ''),
        drumsVersion: Joi.any().optional().allow(null, ''),
    });

    const {
        register: registerUploadFiles,
        handleSubmit: handleSubmitUploadFiles,
        setValue: setValueUploadFiles,
        getValues: getValuesUploadFiles,
        formState: { errors: errorsUploadFiles },
    } = useForm<UploadFilesInputs>({
        defaultValues: useMemo(
            () => (
                uploadFilesData || undefined
            ),
            [uploadFilesData]
        ),
        resolver: joiResolver(schemaUploadFiles),
    });

    useEffect(() => {
        Object.keys(errorsUploadFiles).forEach((err) => {
            showToast({ type: 'error', message: (errorsUploadFiles as any)[err].message || 'An error has occurred. Please try again.' });
        });
        if (Object.keys(errorsUploadFiles).length === 0) {
            toast.dismiss();
        }
    }, [errorsUploadFiles]);

    const saveUploadFilesDraftValues = useCallback(() => {
        if (setUploadFilesData) setUploadFilesData(getValuesUploadFiles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValuesUploadFiles]);

    const pagesData = useCallback(() => [
        {
            text: 'Track Info',
            leftIcon: <Checkmark />,
            enabled: true,
            completed: hasCompletedTrackInfo,
            onSelected: (index: number) => {
                if (selectedPage === 1) {
                    saveUploadFilesDraftValues();
                }
                setSelectedPage(index);
            }
        },
        {
            text: 'Upload Files',
            leftIcon: <Checkmark />,
            color: 'var(--color-gin)',
            enabled: true,
            completed: hasCompletedUploadFiles,
            onSelected: (index: number) => {
                if (uploadFilesData) {
                    setSelectedPage(index);
                } else if (trackInfoFormRef && trackInfoFormRef.current) {
                    trackInfoFormRef.current.requestSubmit();
                }
            }
        },
    ], [hasCompletedTrackInfo, hasCompletedUploadFiles, saveUploadFilesDraftValues, selectedPage, trackInfoFormRef, uploadFilesData]);

    const [pages, setPages] = useState<Page[]>(pagesData());

    const handlerUploadFiles: SubmitHandler<UploadFilesInputs> = useCallback((data) => {
        if (pages.length > 0 && data.trackArtwork && data.trackArtwork.length > 0 && formState !== 'upload-files') {
            const tempPages = pages;
            tempPages[0].completed = true;
            setPages(tempPages);
            setSelectedPage(1);
        }
        if (setUploadFilesData) {
            setUploadFilesData({
                artwork: data.artwork,
                trackArtwork: data.trackArtwork,
                originalVersion: data.originalVersion,
                cleanVersion: data.cleanVersion,
                instrumentalVersion: data.instrumentalVersion,
                acapellaVersion: data.acapellaVersion,
                bassVersion: data.bassVersion,
                melodyVersion: data.melodyVersion,
                drumsVersion: data.drumsVersion,
            });
        }
        if (setHasCompletedUploadFiles) setHasCompletedUploadFiles(true);
    }, [formState, pages, setHasCompletedUploadFiles, setUploadFilesData]);

    const TrackInfo = useMemo(() => {
        return (
            <form ref={trackInfoFormRef} onSubmit={handleSubmitTrackInfo(handlerTrackInfo)}>
                <ApplicationSectionTitle title="Track info" />
                <ArtistApplicationInputField
                    placeholder="Track Title"
                    inputProps={{ ...registerTrackInfo('title', { required: true }) }}
                    errorMessage={errorsTrackInfo && errorsTrackInfo.title ? errorsTrackInfo.title.message : undefined}
                />

                <ArtistApplicationInputField
                    placeholder="Artist(s) Name"
                    inputProps={{ ...registerTrackInfo('artist_name', { required: true }) }}
                    errorMessage={errorsTrackInfo && errorsTrackInfo.artist_name ? errorsTrackInfo.artist_name.message : undefined}
                />

                <ArtistApplicationInputField
                    placeholder="Genre"
                    dropdown={genres?.map((g) => g.name) || []}
                    setText={(text) => {
                        if (text !== 'genre') setValueTrackInfo('genre', text, { shouldDirty: true });
                    }}
                    text={trackInfoData && trackInfoData.genre ? trackInfoData.genre : undefined}
                    errorMessage={errorsTrackInfo && errorsTrackInfo.genre ? errorsTrackInfo.genre.message : undefined}
                />

                <ApplicationSectionHeader subtitle="Is this track explicit?" />

                <ArtistApplicationBooleanButton
                    value={isExplicit}
                    setValue={(bool: boolean) => {
                        setIsExplicit(bool);
                        setValueTrackInfo('explicit', bool, { shouldValidate: true });
                    }} />
                {errorsTrackInfo && errorsTrackInfo.explicit && isExplicit === null ? <ApplicationErrorText text={errorsTrackInfo.explicit.message} /> : null}

                <ApplicationSectionTitle title="Release Date" />

                <div className={styles['submit-music--select-date']}>
                    <Controller
                        name="release_date"
                        control={controlTrackInfo}
                        render={({ field }) => (
                            <DatePicker
                                placeholderText="MM/DD/YYYY"
                                className={styles['submit-music--select-date-input']}
                                selected={field.value ? new Date(field.value) : null}
                                openToDate={field.value ? new Date(field.value) : today}
                                onChange={(date) => field.onChange(date)}
                                onFocus={(e) => {
                                    e.target.readOnly = true;
                                    e.target.blur();
                                }}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        )}
                    />
                </div>
                {errorsTrackInfo && errorsTrackInfo.release_date ? <ApplicationErrorText text="Release Date has to be selected." /> : null}

                <ApplicationSectionTitle title="Metadata" />
                <ArtistApplicationInputField
                    placeholder="ISRC"
                    inputProps={{ ...registerTrackInfo('isrc', { required: false }) }}
                    displayErrorText={false}
                    rightIcon={<CustomIcon type="info-icon" tooltip="An ISRC (International Standard Recording Code) is a unique identifier for your track. You can get an ISRC from your original music distributor (Distrokid, United Masters etc.), record label, or a national ISRC agency." />}
                    errorMessage={errorsTrackInfo && errorsTrackInfo.isrc ? errorsTrackInfo.isrc.message : undefined}
                />
                {errorsTrackInfo && errorsTrackInfo.isrc
                    ? <ApplicationErrorText text={errorsTrackInfo.isrc.message} />
                    : null
                }
                <ArtistApplicationInputField
                    placeholder="Record Label (Optional)"
                    inputProps={{ ...registerTrackInfo('label', { required: false }) }}
                    errorMessage={errorsTrackInfo && errorsTrackInfo.label ? errorsTrackInfo.label.message : undefined}
                />

                <ArtistApplicationInputField
                    placeholder="Produced By (Optional)"
                    inputProps={{ ...registerTrackInfo('produced_by', { required: false }) }}
                    errorMessage={errorsTrackInfo && errorsTrackInfo.produced_by ? errorsTrackInfo.produced_by.message : undefined}
                />

                <ArtistApplicationButton
                    text="Continue"
                    onBack={handleBackHandler}
                />

            </form>
        );
    }, [controlTrackInfo,
        errorsTrackInfo,
        handleBackHandler,
        handleSubmitTrackInfo,
        handlerTrackInfo,
        isExplicit,
        registerTrackInfo,
        setValueTrackInfo,
        today,
        trackInfoData,
        trackInfoFormRef,
        genres]);

    const UploadFiles = useMemo(() => {
        return (
            <form ref={uploadFilesFormRef} onSubmit={handleSubmitUploadFiles(handlerUploadFiles)}>
                {formState === 'upload-files' ? (
                    <>
                        <ApplicationSectionTitle title="Your Profile Photo" />
                        <ApplicationSectionHeader subtitle="Drag and drop your  profile photo here, or click the button below." />
                        <FileUploader
                            key="artwork"
                            hint="Please provide a clear photo of your face(s). 5MB File Limit."
                            accept="image"
                            filename={uploadFilesData && uploadFilesData.artwork && uploadFilesData.artwork[0] ? uploadFilesData.artwork[0].name : undefined}
                            setValue={(files) => {
                                setValueUploadFiles('artwork', files);
                                setShowProfilePhoto(true);
                                setUploadedImage(URL.createObjectURL(files[0]));
                            }}
                            errorMessage={errorsUploadFiles && errorsUploadFiles.artwork ? errorsUploadFiles.artwork.message : null}
                        />
                    </>
                ) : null}

                <ApplicationSectionTitle title="Track artwork" />
                <FileUploader
                    key="trackArtwork"
                    hints={['Please provide a high-quality 3000x3000px PNG or JPG.', '5MB File Limit.']}
                    accept="image"
                    filename={uploadFilesData && uploadFilesData.trackArtwork && uploadFilesData.trackArtwork[0] ? uploadFilesData.trackArtwork[0].name : undefined}
                    inputProps={{ ...registerUploadFiles('trackArtwork', { required: true }) }}
                    setValue={(files) => {
                        setShowTrackArtwork(true);
                        setUploadedTrackArtwork(URL.createObjectURL(files[0]));
                    }}
                    errorMessage={errorsUploadFiles && errorsUploadFiles.trackArtwork ? errorsUploadFiles.trackArtwork.message : null}
                />

                <ApplicationSectionTitle title="File Uploads" />

                <ApplicationSectionHeader subtitle="Original Version" />
                <FileUploader
                    key="originalVersion"
                    accept="wav"
                    filename={uploadFilesData && uploadFilesData.originalVersion && uploadFilesData.originalVersion[0] ? uploadFilesData.originalVersion[0].name : undefined}
                    inputProps={{ ...registerUploadFiles('originalVersion', { required: true }) }}
                    setValue={(files) => {
                        setValueUploadFiles('originalVersion', Array.from(files) as unknown as FileList);
                    }}
                    errorMessage={errorsUploadFiles && errorsUploadFiles.originalVersion ? errorsUploadFiles.originalVersion.message : null}
                />

                {trackInfoData && trackInfoData.explicit ? (
                    <>
                        <ApplicationSectionHeader subtitle="Clean Version" />
                        <FileUploader
                            key="cleanVersion"
                            accept="wav"
                            filename={uploadFilesData && uploadFilesData.cleanVersion && uploadFilesData.cleanVersion[0] ? uploadFilesData.cleanVersion[0].name : undefined}
                            inputProps={{ ...registerUploadFiles('cleanVersion', { required: trackInfoData && trackInfoData.explicit ? trackInfoData.explicit : false }) }}
                            setValue={(files) => {
                                setValueUploadFiles('cleanVersion', Array.from(files) as unknown as FileList);
                            }}
                            errorMessage={errorsUploadFiles && errorsUploadFiles.cleanVersion ? errorsUploadFiles.cleanVersion.message : null}
                        />
                    </>) : null}
                <ApplicationSectionTitle title="Additional Versions" />

                <ApplicationSectionSmall text="Additional versions like Instrumental and Acapella are optional, but increase the likelihood that your music will be downloaded by DJs and promoted across the BPM Music ecosystem" />

                <ArtistApplicationDropdown
                    title="Instrumental"
                    bottomComponent={
                        <FileUploader
                            key="instrumentalVersion"
                            accept="wav"
                            filename={uploadFilesData && uploadFilesData.instrumentalVersion && uploadFilesData.instrumentalVersion[0] ? uploadFilesData.instrumentalVersion[0].name : undefined}
                            inputProps={{ ...registerUploadFiles('instrumentalVersion', { required: false }) }}
                            setValue={(files) => {
                                setValueUploadFiles('instrumentalVersion', Array.from(files) as unknown as FileList);
                            }}
                        />
                    } />

                <ArtistApplicationDropdown
                    title="Acapella"
                    bottomComponent={
                        <FileUploader
                            key="acapellaVersion"
                            accept="wav"
                            filename={uploadFilesData && uploadFilesData.acapellaVersion && uploadFilesData.acapellaVersion[0] ? uploadFilesData.acapellaVersion[0].name : undefined}
                            inputProps={{ ...registerUploadFiles('acapellaVersion', { required: false }) }}
                            setValue={(files) => {
                                setValueUploadFiles('acapellaVersion', Array.from(files) as unknown as FileList);
                            }}
                        />
                    } />

                <ArtistApplicationDropdown
                    title="Bass"
                    bottomComponent={
                        <FileUploader
                            key="bassVersion"
                            accept="wav"
                            filename={uploadFilesData && uploadFilesData.bassVersion && uploadFilesData.bassVersion[0] ? uploadFilesData.bassVersion[0].name : undefined}
                            inputProps={{ ...registerUploadFiles('bassVersion', { required: false }) }}
                            setValue={(files) => {
                                setValueUploadFiles('bassVersion', Array.from(files) as unknown as FileList);
                            }}
                        />
                    } />

                <ArtistApplicationDropdown
                    title="Melody"
                    bottomComponent={
                        <FileUploader
                            key="melodyVersion"
                            accept="wav"
                            filename={uploadFilesData && uploadFilesData.melodyVersion && uploadFilesData.melodyVersion[0] ? uploadFilesData.melodyVersion[0].name : undefined}
                            inputProps={{ ...registerUploadFiles('melodyVersion', { required: false }) }}
                            setValue={(files) => {
                                setValueUploadFiles('melodyVersion', Array.from(files) as unknown as FileList);
                            }}
                        />
                    } />
                <ArtistApplicationDropdown
                    title="Drums"
                    bottomComponent={
                        <FileUploader
                            key="drumsVersion"
                            accept="wav"
                            filename={uploadFilesData && uploadFilesData.drumsVersion && uploadFilesData.drumsVersion[0] ? uploadFilesData.drumsVersion[0].name : undefined}
                            inputProps={{ ...registerUploadFiles('drumsVersion', { required: false }) }}
                            setValue={(files) => {
                                setValueUploadFiles('drumsVersion', Array.from(files) as unknown as FileList);
                            }}
                        />
                    } />
                <ArtistApplicationButton
                    text="Continue"
                    onBack={() => formState === 'upload-files' ? handleBack() : setSelectedPage(0)}
                    isLoading={isLoading}
                />

            </form>
        );
    }, [
        errorsUploadFiles,
        formState,
        handleBack,
        handleSubmitUploadFiles,
        handlerUploadFiles,
        isLoading,
        registerUploadFiles,
        setValueUploadFiles,
        trackInfoData,
        uploadFilesData,
        uploadFilesFormRef
    ]);

    useEffect(() => {
        setPages(pagesData());
    }, [pagesData]);

    const currentPage = useMemo(() => {
        switch (formState) {
            case 'track-info':
                return TrackInfo;
            case 'upload-files':
                return UploadFiles;
            default:
                switch (selectedPage) {
                    case 0:
                        return TrackInfo;
                    case 1:
                        return UploadFiles;
                    default:
                        return TrackInfo;
                }
        }
    }, [formState, TrackInfo, UploadFiles, selectedPage]);

    return (
        <div className={styles['submit-music']}>
            {formState === 'full' ? <PageSwitcher pages={pages} selected={selectedPage} /> : null}
            {currentPage}
            <ActionModal
                hideCancel
                variant="dark"
                isOpen={showUploadProfilePhoto}
            >
                <CropImage
                    title="Profile Photo"
                    subtitle="For best results, please make sure your profile photo is at least 500x500 pixels."
                    imageUrl={uploadedImage}
                    onCancel={() => setShowProfilePhoto(false)}
                    onUpload={(file) => {
                        setValueUploadFiles('artwork', [file] as unknown as FileList);
                        setShowProfilePhoto(false);
                    }} />
            </ActionModal>

            <ActionModal
                hideCancel
                variant="dark"
                isOpen={showUploadTrackArtwork}
            >
                <CropImage
                    title="Track Artwork"
                    subtitle="For best results, please make sure your profile photo is at least 500x500 pixels."
                    imageUrl={uploadedTrackArtwork}
                    onCancel={() => setShowTrackArtwork(false)}
                    onUpload={(file) => {
                        setValueUploadFiles('trackArtwork', [file] as unknown as FileList);
                        setShowTrackArtwork(false);
                    }} />
            </ActionModal>
        </div>
    );
}
