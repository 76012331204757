import { Page, PageSwitcher } from '@bpm-web-app/components';
import { capitalizeFirstLetter, errorToString, isValidURL, showToast } from '@bpm-web-app/utils';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { apiKey, ArtistPortal } from '@bpm-web-app/api-client';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useProfileStatus } from '@bpm-web-app/swr-hooks';
import { ArtistApplicationStatus } from '@bpm-web-app/download-api-sdk';
import Checkmark from '../../../../assets/icons/artist-platform/checkmark.svg';
import { ActionModal } from '../../../shared/action-modal/action-modal';
import { ArtistApplicationButton } from './artist-application-button/artist-application-button';
import { ApplicationHeader } from './artist-application-header/artist-application-header';
import { ArtistApplicationInputField } from './artist-application-inputfield/artist-application-inputfield';
import { ApplicationSectionTitle, ApplicationSectionHeader, ApplicationErrorText } from './artist-application-text/artist-application-text';
import styles from './artist-application.module.css';
import { fileInputsToDictionary, SubmitMusic, TrackInfoInputs, UploadFilesInputs } from './submit-music/submit-music';
import { LoadingIcon } from '../../../shared/loading-icon/loading-icon';
import { ArtistApplicationMetadata } from './artist-application-metadata/artist-application-metadata';
import { Checkbox } from '../../../shared';

interface ArtistApplicationData {
    basicInfo?: BasicInfoInputs;
    detailedInfo?: DetailedInfoInputs;
    trackInfoData?: TrackInfoInputs;
    uploadFilesData?: UploadFilesInputs;
}

interface BasicInfoInputs {
    name: string;
    bio?: string;
    social_links: string[]
}

interface DetailedInfoInputs {
    full_name: string;
    city: string;
    country: string;
    state: string;
    press?: FileList;
    links: string[]
}

export function ArtistApplication() {
    const router = useRouter();

    const [selectedPage, setSelectedPage] = useState<number>(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submissionAcknowledgement, setSubmissionAcknowledgement] = useState(false);
    const [missingAcknowledgement, setMissingAcknowledgement] = useState(false);
    const { data: profileDate, mutate } = useProfileStatus();

    const [artistData, setArtistData] = useState<ArtistApplicationData>();

    const basicInfoFormRef: MutableRefObject<HTMLFormElement> = useRef();
    const detailedInfoRef: MutableRefObject<HTMLFormElement> = useRef();
    const trackInfoFormRef: MutableRefObject<HTMLFormElement> = useRef();
    const uploadFilesFormRef: MutableRefObject<HTMLFormElement> = useRef();

    const [hasCompletedTrackInfo, setHasCompletedTrackInfo] = useState(false);
    const [hasCompletedUploadFiles, setHasCompletedUploadFiles] = useState(false);
    const [hasCompletedBasicInfo, setHasCompletedBasicInfo] = useState(false);

    const [hasBegan, setHasBegan] = useState(false);

    // BASIC INFO FORM
    const schemaBasicInfo = Joi.object({
        name: Joi.string().required().messages({ 'string.empty': 'Artist name is required.' }),
        bio: Joi.string().optional().allow(null, ''),
        social_links: Joi.array().items(Joi.string().allow('').custom((value, helper) => {
            if (isValidURL(value) || value === '') {
                return value;
            }
            return helper.error('invalid.link');
        }).messages({ 'invalid.link': 'Please try again with a valid link.' })).min(1).custom((value, helper) => {
            let isValid = false;
            if (value.length > 0) {
                value.forEach((item: string) => {
                    if (item !== '') {
                        isValid = true;
                    }
                });
                if (isValid) {
                    return value;
                }
            }
            return helper.error('array.min');
        }).required().messages({ 'array.min': 'At least one social media link is required.' })
    });

    const {
        register: registerBasicInfo,
        handleSubmit: handleSubmitBasicInfo,
        formState: { errors: errorsBasicInfo, isDirty: isDirtyBasicInfo },
    } = useForm<BasicInfoInputs>({
        defaultValues: useMemo(
            () => ({
                name: '',
                bio: '',
                social_links: []
            }),
            []
        ),
        resolver: joiResolver(schemaBasicInfo),
    });

    const handlerBasicInfo: SubmitHandler<BasicInfoInputs> = useCallback((data) => {
        if (data.name !== '') {
            setArtistData({
                ...artistData,
                basicInfo: {
                    name: data.name,
                    bio: data.bio,
                    social_links: data.social_links
                }
            });
            setSelectedPage(1);
            setHasCompletedBasicInfo(true);
        }
    }, [artistData]);

    useEffect(() => {
        Object.keys(errorsBasicInfo).forEach((err) => {
            showToast({ type: 'error', message: (errorsBasicInfo as any)[err].message || 'An error has occurred. Please try again.' });
        });
        if (Object.keys(errorsBasicInfo).length === 0) {
            toast.dismiss();
        }
    }, [errorsBasicInfo]);

    useEffect(() => {
        const beforeunload = () => {
            if ((isDirtyBasicInfo || selectedPage > 0) && !submitted) {
                return 'You have unsaved changes.';
            }
            return undefined;
        };
        const routeChangeStart = () => {
            if ((isDirtyBasicInfo || selectedPage > 0) && !submitted) {
                // eslint-disable-next-line no-alert
                if (!window.confirm('You have unsaved changes.')) {
                    router.events.emit('routeChangeError');
                    throw new Error('stop navigating');
                }
            }
            return undefined;
        };
        window.addEventListener('beforeunload', beforeunload);
        router.events.on('routeChangeStart', routeChangeStart);
        return () => {
            window.removeEventListener('beforeunload', beforeunload);
            router.events.off('routeChangeStart', routeChangeStart);
        };
    }, [isDirtyBasicInfo, selectedPage, router.events, submitted]);

    const handleBack = useCallback(() => {
        if (selectedPage > 0) {
            setSelectedPage(selectedPage - 1);
        }
    }, [selectedPage]);

    const pagesData = useCallback(() => [
        {
            text: 'Basic Info',
            leftIcon: <Checkmark />,
            enabled: true,
            completed: hasCompletedBasicInfo,
            onSelected: (index: number) => {
                if (selectedPage > 0) {
                    setSelectedPage(index);
                }
            }
        },
        {
            text: 'Submit Music',
            leftIcon: <Checkmark />,
            color: 'var(--color-gin)',
            enabled: true,
            completed: hasCompletedTrackInfo,
            form: 'basicInfo',
            buttonType: 'submit',
            onSelected: (index: number) => {
                if (artistData && artistData.basicInfo) {
                    setSelectedPage(index);
                } else if (basicInfoFormRef && basicInfoFormRef.current) {
                    basicInfoFormRef.current.requestSubmit();
                }
            }
        },
        {
            text: 'Upload Files',
            leftIcon: <Checkmark />,
            color: 'var(--color-gin)',
            enabled: true,
            completed: hasCompletedUploadFiles,
            onSelected: (index: number) => {
                if (artistData && artistData.trackInfoData) {
                    setSelectedPage(index);
                } else if (trackInfoFormRef && trackInfoFormRef.current) {
                    trackInfoFormRef.current.requestSubmit();
                }
            }
        },
        {
            text: 'Review',
            leftIcon: <Checkmark />,
            color: 'var(--color-gin)',
            enabled: true,
            completed: selectedPage === 4,
            onSelected: (index: number) => {
                if (artistData && artistData.uploadFilesData && artistData.trackInfoData) {
                    setSelectedPage(index);
                } else if (artistData && artistData.detailedInfo && artistData.trackInfoData === undefined) {
                    if (trackInfoFormRef && trackInfoFormRef.current) {
                        trackInfoFormRef.current.requestSubmit();
                    }
                } else if (artistData && artistData.trackInfoData && artistData.uploadFilesData === undefined) {
                    if (uploadFilesFormRef && uploadFilesFormRef.current) {
                        uploadFilesFormRef.current.requestSubmit();
                    }
                }
            },
        },
    ], [artistData, hasCompletedBasicInfo, hasCompletedTrackInfo, hasCompletedUploadFiles, selectedPage]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [selectedPage]);

    const [pages, setPages] = useState<Page[]>(pagesData());

    const BasicInfo = useMemo(() => {
        return (
            <form id="basicInfo" key="basicInfo" onSubmit={handleSubmitBasicInfo(handlerBasicInfo)} ref={basicInfoFormRef}>
                <ApplicationSectionTitle title="Basic Info" />
                <ArtistApplicationInputField
                    placeholder="Your Artist Name"
                    inputProps={{ ...registerBasicInfo('name', { required: true }) }}
                    errorMessage={errorsBasicInfo && errorsBasicInfo.name ? errorsBasicInfo.name.message : undefined}
                />
                <ArtistApplicationInputField
                    placeholder="Your Artist Bio (optional)"
                    multiline
                    inputProps={{ ...registerBasicInfo('bio', { required: false }) }}
                    errorMessage={errorsBasicInfo && errorsBasicInfo.bio ? errorsBasicInfo.bio.message : undefined}
                />

                <ApplicationSectionTitle title="Your Online Presence" />
                <ArtistApplicationInputField
                    placeholder="Link to your primary social media"
                    inputProps={{ ...registerBasicInfo('social_links.0', { required: true }) }}
                    isLink
                    displayErrorText={false}
                    errorMessage={errorsBasicInfo && errorsBasicInfo.social_links && errorsBasicInfo.social_links[0] ? (errorsBasicInfo.social_links[0] as unknown as FieldError).message : undefined}
                />
                {errorsBasicInfo && errorsBasicInfo.social_links && errorsBasicInfo.social_links[0]
                    ? <ApplicationErrorText text={((errorsBasicInfo.social_links[0] as unknown as FieldError).message)} />
                    : null
                }
                {errorsBasicInfo && errorsBasicInfo.social_links ? <ApplicationErrorText text={(errorsBasicInfo.social_links as unknown as FieldError).message} /> : null}
                <ArtistApplicationInputField
                    placeholder="Link to additional primary social media (optional)"
                    inputProps={{ ...registerBasicInfo('social_links.1', { required: false }) }}
                    isLink
                    displayErrorText={false}
                    errorMessage={errorsBasicInfo && errorsBasicInfo.social_links && errorsBasicInfo.social_links[1] ? (errorsBasicInfo.social_links[1] as unknown as FieldError).message : undefined}
                />
                {errorsBasicInfo && errorsBasicInfo.social_links && errorsBasicInfo.social_links[1]
                    ? <ApplicationErrorText text={((errorsBasicInfo.social_links[1] as unknown as FieldError).message)} />
                    : null
                }
                <ArtistApplicationInputField
                    placeholder="Link to your music (Spotify, Apple Music etc)."
                    inputProps={{ ...registerBasicInfo('social_links.2', { required: true }) }}
                    isLink
                    displayErrorText={false}
                    errorMessage={errorsBasicInfo && errorsBasicInfo.social_links && errorsBasicInfo.social_links[2] ? (errorsBasicInfo.social_links[2] as unknown as FieldError).message : undefined}
                />
                {errorsBasicInfo && errorsBasicInfo.social_links && errorsBasicInfo.social_links[2]
                    ? <ApplicationErrorText text={((errorsBasicInfo.social_links[2] as unknown as FieldError).message)} />
                    : null
                }
                <ArtistApplicationButton
                    text="Continue"
                />
            </form>
        );
    }, [handleSubmitBasicInfo, handlerBasicInfo, registerBasicInfo, errorsBasicInfo]);

    const SubmitMusicSection = useMemo(() => {
        return (
            <div>
                <ApplicationSectionTitle title="Track Submission" />

                <ApplicationSectionHeader subtitle="To complete your artist application, please submit your first track to BPM Supreme. This will help us determine if your music is a fit with our curation strategy and audience." />
                <SubmitMusic
                    trackInfoData={artistData && artistData.trackInfoData ? artistData.trackInfoData : undefined}
                    setTrackInfoData={(data) => {
                        setArtistData({ ...artistData, trackInfoData: data });
                    }}
                    handleBack={handleBack}
                    trackInfoFormRef={trackInfoFormRef}
                    hasCompletedTrackInfo={hasCompletedTrackInfo}
                    setHasCompletedTrackInfo={(e) => {
                        setHasCompletedTrackInfo(e);
                        if (e) {
                            setSelectedPage(2);
                        }
                    }}
                    formState="track-info"
                    artistName={artistData?.basicInfo?.name}
                />

            </div>
        );
    }, [artistData, handleBack, hasCompletedTrackInfo]);

    const UploadFilesSection = useMemo(() => {
        return (
            <div>
                <SubmitMusic
                    trackInfoData={artistData && artistData.trackInfoData ? artistData.trackInfoData : undefined}
                    uploadFilesData={artistData && artistData.uploadFilesData ? artistData.uploadFilesData : undefined}
                    setUploadFilesData={(data) => {
                        setArtistData({ ...artistData, uploadFilesData: data });
                    }}
                    handleBack={handleBack}
                    uploadFilesFormRef={uploadFilesFormRef}
                    hasCompletedUploadFiles={hasCompletedUploadFiles}
                    setHasCompletedUploadFiles={(e) => {
                        setHasCompletedUploadFiles(e);
                        if (e) {
                            setSelectedPage(3);
                        }
                    }}
                    formState="upload-files"

                />

            </div>
        );
    }, [artistData, handleBack, hasCompletedUploadFiles]);

    const handleSubmit = useCallback(() => {
        if (artistData && artistData.basicInfo && artistData.trackInfoData && artistData.uploadFilesData && submissionAcknowledgement) {
            // TODO: Send artist data to API
            setIsSubmitting(true);
            const filesToUpload: { [key: string]: File } = fileInputsToDictionary(artistData.trackInfoData, artistData.uploadFilesData);
            Promise.all(Object.keys(filesToUpload).map((key) => {
                return ArtistPortal.uploadAudioFile(filesToUpload[key]).then((path) => {
                    return {
                        version_name: key,
                        track_path: path
                    };
                });
            })).then((tracks) => {
                const formData = new FormData();

                Object.keys(artistData.basicInfo!).forEach((_key) => {
                    const key = _key as keyof BasicInfoInputs;
                    const value = artistData.basicInfo![key];
                    formData.append(key, typeof value === 'string' ? value : JSON.stringify(value));
                });

                formData.append('artwork', artistData.uploadFilesData!.artwork![0], artistData.uploadFilesData!.artwork![0].name);

                formData.append('track_artwork', artistData.uploadFilesData!.trackArtwork[0], artistData.uploadFilesData!.trackArtwork[0].name);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const trackSubmit: { [key: string]: any } = {};
                Object.keys(artistData.trackInfoData!).forEach((_key) => {
                    const key = _key as keyof TrackInfoInputs;
                    const value = artistData.trackInfoData![key];
                    trackSubmit[key] = value;
                });
                // eslint-disable-next-line @typescript-eslint/dot-notation
                trackSubmit['tracks'] = tracks;
                formData.append('track_submit', JSON.stringify(trackSubmit));
                const key = apiKey();
                const headers = {} as { [key: string]: string };
                if (key) {
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    headers['Authorization'] = key;
                }
                // eslint-disable-next-line @typescript-eslint/dot-notation
                return fetch(`${process.env['NEXT_PUBLIC_DOWNLOAD_API_BASE_PATH']}/artist-portal/application`, {
                    method: 'POST',
                    credentials: 'include',
                    body: formData,
                    headers
                });
            }).then(async (data) => {
                if (data.ok) {
                    if (profileDate) {
                        profileDate.data.application_status = ArtistApplicationStatus.Approved;
                        mutate({ data: { ...profileDate.data } }, { revalidate: false });
                    }
                    // setIsSubmitting(false);
                    // setSubmitted(true);
                    router.push('/dashboard');
                } else {
                    const errorData = await data.json();
                    throw errorData;
                }

                return null;
            }).catch((err) => {
                setIsSubmitting(false);
                showToast({ type: 'error', title: errorToString(err) });
            });
        } else if (!submissionAcknowledgement) {
            setMissingAcknowledgement(true);
        }
    }, [artistData, submissionAcknowledgement, mutate, profileDate, router]);

    const Review = useMemo(() => {
        return (
            <div>
                <ApplicationSectionTitle title="Your Artist Profile" />

                <ApplicationSectionHeader subtitle="If approved, your artist profile will appear like this on the BPM Supreme platform." />

                {artistData && artistData.basicInfo && artistData.uploadFilesData && artistData.uploadFilesData.artwork && artistData.uploadFilesData.artwork.length > 0 ?
                    <div className={styles['artist-application--preview']}>
                        {/* Profile Image */}
                        <img alt="PreviewImage" src={URL.createObjectURL(artistData.uploadFilesData.artwork[0])} className={styles['artist-application--preview--image-rounded']} />
                        {/* Artist Title */}
                        <div className={styles['artist-application--preview--text']}>{artistData.basicInfo.name}</div>
                    </div>
                    : null}

                <ApplicationSectionTitle title="Your Track Submission" />

                <ApplicationSectionHeader subtitle="If approved, your track will appear like this on the BPM Supreme platform." />
                {artistData && artistData.trackInfoData && artistData.uploadFilesData && artistData.uploadFilesData.trackArtwork.length > 0 ?
                    <div className={styles['artist-application--preview']}>
                        {/* Track Image */}
                        <img alt="PreviewImage" src={URL.createObjectURL(artistData.uploadFilesData.trackArtwork[0])} className={styles['artist-application--preview--image']} />
                        <div className={styles['artist-application--preview--column']}>
                            {/* Track Title */}
                            <div className={styles['artist-application--preview--text']}>{artistData.trackInfoData.title}</div>
                            {/* Track Artist */}
                            <div className={styles['artist-application--preview--text-thin']}>{artistData.trackInfoData.artist_name}</div>
                            {/* Track Genre */}
                            <div className={styles['artist-application--preview--text-small']}>{capitalizeFirstLetter(artistData.trackInfoData.genre)}</div>
                        </div>
                    </div>
                    : null}

                {artistData && artistData.trackInfoData ?
                    <ArtistApplicationMetadata
                        releaseDate={artistData.trackInfoData.release_date}
                        isrc={artistData.trackInfoData.isrc}
                        recordLabel={artistData.trackInfoData.label}
                        producedBy={artistData.trackInfoData.produced_by} />
                    : null}

                <div
                    className={styles['artist-application--checkbox']}
                >
                    <Checkbox
                        checked={submissionAcknowledgement}
                        onChange={(e) => {
                            setSubmissionAcknowledgement(e.target.checked);
                            setMissingAcknowledgement(false);
                        }}
                    />
                    <button
                        className={styles['artist-application--terms-text-container']}
                        type="button"
                        onClick={() => {
                            setSubmissionAcknowledgement((state) => !state);
                            setMissingAcknowledgement(false);
                        }}>
                        <span className={styles['artist-application--section-header']}>By submitting, you confirm that your uploads comply with our </span>
                        <a href="https://bpmmusic.io/terms" target="_blank" className={classNames(styles['artist-application--section-header'], 'underline-link')} rel="noreferrer">Terms of Use</a>
                        <span className={styles['artist-application--section-header']}> and you don&apos;t infringe anyone else&apos;s rights.</span>
                    </button>
                </div>
                {submissionAcknowledgement === false && missingAcknowledgement === true ? <ApplicationErrorText text="You must agree to our Terms of Use to submit the application." /> : null}
                <ArtistApplicationButton
                    text="Submit"
                    onBack={handleBack}
                    onContinue={handleSubmit}
                    isLoading={isSubmitting}
                />
            </div>
        );
    }, [artistData, handleBack, handleSubmit, isSubmitting, missingAcknowledgement, submissionAcknowledgement]);

    const currentPage = useMemo(() => {
        switch (selectedPage) {
            case 0:
                return BasicInfo;
            case 1:
                return SubmitMusicSection;
            case 2:
                return UploadFilesSection;
            case 3:
                return Review;
            case 4:
                return Review;
            default:
                return null;
        }
    }, [selectedPage, BasicInfo, SubmitMusicSection, UploadFilesSection, Review]);

    useEffect(() => {
        setPages(pagesData());
    }, [pagesData]);

    return (
        <div className={styles['artist-application']}>
            <div className={styles['artist-application--container']}>
                <ApplicationHeader title="Apply to our artist network" subtitle="Get your music played by pro DJs worldwide through BPM Supreme." />
                <PageSwitcher pages={pages} selected={selectedPage} />
                {currentPage}
            </div>
            <ActionModal
                title="Submission Complete"
                subtitle={'Thank you for adding your music to BPM For Artists! We’ve got your profile and uploads. We will notify you once your profile is accessible and your music is available on BPM Supreme later this summer.\n\nIn the meantime, learn more about BPM For Artists or come back to submit additional new music.'}
                confirmButtonText="Dismiss"
                onConfirm={() => {
                    router.push('/dashboard');
                }}
                hideCancel
                onClose={() => {
                    router.push('/dashboard');
                }}
                variant="dark"
                isOpen={submitted}
            />
            <ActionModal
                headerTitle=""
                title="Before you Begin"
                subtitle={
                    <div>
                        <div className={styles['artist-application--begin-action-modal-title']}>To complete the artist application, there are a few things that will be required, please make sure you have these handy:</div>
                        <div className={styles['artist-application--begin-action-modal-body']}>
                            {`
                            • Artist profile photo (500x500 minimum, .JPG or .PNG)
                            • .WAV track submission
                            • Track submission artwork (3000x3000 .JPG or .PNG)
                            `
                            }
                        </div>
                    </div>
                }
                confirmButtonText="Continue to Application"
                onConfirm={() => {
                    setHasBegan(true);
                }}
                hideCancel
                variant="dark"
                isOpen={!hasBegan}
            />
            <ActionModal
                headerTitle={<div className={styles['artist-application__progress-popup-title']}><LoadingIcon />Submitting Information</div>}
                subtitle="Your artist profile is uploading."
                hideCancel
                variant="dark"
                isOpen={isSubmitting}
            />
        </div>
    );
}
